import React from "react";
import Header from "../components/atoms/header";
import Head from "../components/ogranisms/head";
import CEO from "../components/ogranisms/CEO/CEO";
import FullscreenGeneral from "../components/atoms/Fullscreen/FullscreenGeneral";
import { Helmet } from "react-helmet";

export default function practicalUses() {
  return (
    <div>
      <Helmet>
        <title>CEO</title>
        <link rel="canonical" href="https://masterwizr.com/ceo" />
      </Helmet>
      <FullscreenGeneral />
      <Head
        title="Welcome to the WiZR workspace"
        description="Welcome to the WiZR workspace
        The place to organize, present, share and collaborate. Cultivating what matters the most to you."
        ogTitle="About us"
      />
      <Header />
      <CEO />
      {/* <Footer /> */}
    </div>
  );
}
