// extracted by mini-css-extract-plugin
export var active = "styles-module--active--e0db2";
export var activeTab = "styles-module--activeTab--e9325";
export var arrow = "styles-module--arrow--36bdd";
export var bottomPart = "styles-module--bottomPart--7b024";
export var bottomSlide = "styles-module--bottomSlide--76a1d";
export var bottomarrow = "styles-module--bottomarrow--9815c";
export var container = "styles-module--container--ed106";
export var content = "styles-module--content--e787b";
export var frame = "styles-module--frame--a08be";
export var highlightsContainer = "styles-module--highlightsContainer--71cbf";
export var introContainer = "styles-module--introContainer--002cb";
export var l27 = "styles-module--l27--9650d";
export var loader = "styles-module--loader--a3b0a";
export var loaderContainer = "styles-module--loaderContainer--30459";
export var pres = "styles-module--pres--a38ba";
export var presentationContainer = "styles-module--presentationContainer--2ae5d";
export var rightarrow = "styles-module--rightarrow--012ce";
export var selected = "styles-module--selected--47cce";
export var slide = "styles-module--slide--4f0b1";
export var slideContent = "styles-module--slideContent--22b6e";
export var slider = "styles-module--slider--3d060";
export var slides = "styles-module--slides--e0013";
export var tabs = "styles-module--tabs--983e3";
export var vdContainer = "styles-module--vdContainer--dbd70";