import * as styles from './styles.module.scss'
import React, { useEffect, useState, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import ArrowRight from '../../../images/icon-arrow-right.svg'
import ArrowLeft from '../../../images/icon-arrow-left.svg'
import { joneData } from '../../../constants/modulesData'
import { navigate } from "gatsby";

export default function CEO({ module, location, ...rest }) {
  const [index, setCurrentIndex] = useState(0)
  const [prevIndex, setPrevIndex] = useState(null); // Track the previous index
  const selectedIndex = joneData[index];
  const videoRef = useRef(null);

  useEffect(() => {
    if (module?.includes('wizr-x')) {
      setCurrentIndex(3);
    } else if (module?.includes('Connect')) {
      setCurrentIndex(2);
    } else if (module?.includes('library')) {
      setCurrentIndex(0);
    } else if (module?.includes('studio')) {
      setCurrentIndex(1);
    } else if (module?.includes('mpublisher')) {
      setCurrentIndex(4);
    } else if (module?.includes('wizr-connect')) {
      setCurrentIndex(2);
    } else if (module?.includes('magazine')) {
      setCurrentIndex(5);
    } else if (module?.includes('wizr-up')) {
      setCurrentIndex(6);
    } else {
      setCurrentIndex(0)
    }
  }, [module]);

  useEffect(() => {
    // Function to pause the video at the given index
    const pauseVideoAtIndex = (idx) => {
      const videoElement = document.getElementById(`video-${idx}`);
      if (videoElement) {
        videoElement.pause();
      }
    };

    // If there's a previous index and it's not the current index, pause its video
    if (prevIndex !== null && prevIndex !== index) {
      pauseVideoAtIndex(prevIndex);
    }

    // Update the previous index after handling the current change
    setPrevIndex(index);
  }, [index, prevIndex]);

  const handleNext = () => {
    const nextSlide = (index + 1) % joneData.length;
    setCurrentIndex(nextSlide);
  };

  const handlePrevious = () => {
    const prevSlide = (index - 1 + joneData.length) % joneData.length;
    setCurrentIndex(prevSlide);
  };

  const handleButtonClick = (targetIndex) => {
    setCurrentIndex(targetIndex);
  };

  return (
    <div
      style={{ background: selectedIndex?.background }}
      className={styles.container}
    >
      <button onClick={handlePrevious} className={`${styles.arrow} ${styles.leftarrow}`}>
        <ArrowLeft />
      </button>
      <Carousel
        slide={false}
        animation='fade'
        keyboard
        interval={null}
        controls={false}
        indicators={false}
        activeIndex={index}
        className={styles.slides}
      >
        {joneData.map((module, i) => (
          <Carousel.Item index className={styles.slide} key={i}>
            <div className={styles.topPart}>
              <div className={styles.slideContent}>
                <h1>
                  FROM THE FOUNDER
                  <br />
                </h1>
              </div>
              <div className={styles.tabs}>
                {joneData.map((tab, tabIndex) => (
                  <button
                    key={tabIndex}
                    className={index === tabIndex ? styles.activeTab : styles.tab}
                    onClick={() => handleButtonClick(tabIndex)}
                  >
                    {tab.module}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.bottomPart}>
              <div className={styles.vdContainer}>
                <video id={`video-${i}`}  loop src={module?.video} controls />
                </div>
              <span>{module?.description}</span>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <button 
        onClick={handleNext}
        className={`${styles.arrow} ${styles.rightarrow}`}
      >
        <ArrowRight />
      </button>
    </div>
  )
}
